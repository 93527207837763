const diffCounter = {
    'item_single': {
        'lv_26': 1,
        'lv_25': 4,
        'lv_24': 7,
        'lv_23': 24,
        'lv_22': 39,
        'lv_21': 74,
        'lv_20': 84,
        'lv_19': 119,
        'lv_18': 130,
        'lv_17': 153,
        'lv_16': 133,
        'lv_15': 102,
        'lv_14': 68,
        'lv_13': 56,
        'lv_12': 59,
        'lv_11': 77,
        'lv_10': 85,
        'lv_09': 82,
        'lv_08': 91,
        'lv_07': 117,
        'lv_06': 105,
        'lv_05': 74,
        'lv_04': 104,
        'lv_03': 79,
        'lv_02': 36,
        'lv_01': 24
    },
    'item_double': {
        'lv_28': 2,
        'lv_27': 2,
        'lv_26': 9,
        'lv_25': 13,
        'lv_24': 37,
        'lv_23': 46,
        'lv_22': 58,
        'lv_21': 68,
        'lv_20': 91,
        'lv_19': 82,
        'lv_18': 127,
        'lv_17': 83,
        'lv_16': 65,
        'lv_15': 63,
        'lv_14': 38,
        'lv_13': 50,
        'lv_12': 61,
        'lv_11': 50,
        'lv_10': 44,
        'lv_09': 26,
        'lv_08': 20,
        'lv_07': 28,
        'lv_06': 10,
        'lv_05': 10,
        'lv_04': 3,
        'lv_03': 1,
    },
}

export default diffCounter;