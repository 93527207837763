import SSS from './SSS.png'
import SS from './SS.png'
import S from './S.png'
import A from './A.png'
import Ap from './A+.png'
import B from './B.png'
import Bp from './B+.png'
import C from './C.png'
import Cp from './C+.png'
import D from './D.png'
import Dp from './D+.png'
import F from './F.png'

const grades = {
    F, D, Dp, C, Cp, B, Bp, A, Ap, S, SS, SSS
}

export default grades